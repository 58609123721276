<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Manajemen Ahli Gizi
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex
      mb="33px"
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Manajemen Ahli Gizi
      </c-heading>
      <c-button
        h="auto"
        py="12px"
        px="28px"
        color="#FFF"
        border-radius="24px"
        border-color="transparent"
        bg="primary.400"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        as="router-link"
        :to="{ name: 'admin.nutritionists.add' }"
      >
        <inline-svg
          v-chakra
          :src="require('@/assets/icons/icon-plus.svg')"
          height="19"
          width="19"
          fill="white"
          mr="10px"
        />
        Tambah Ahli Gizi
      </c-button>
    </c-flex>
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
      class="container"
    >
      <c-flex
        py="25px"
        px="27px"
        border-bottom="1px solid #C4C4C4"
      >
        <c-flex
          justify="space-between"
          align="center"
          flex-grow="1"
        >
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            List of Ahli Gizi
          </c-heading>
          <!-- <c-button
            borderRadius="40px"
            backgroundColor="superLightGray.900"
            pl="20px"
            pr="30px"
            py="10px"
            h="auto"
            color="gray.900"
            fontSize="14px"
            lineHeight="21px"
            fontFamily="Roboto"
            fontWeight="normal"
            display="flex"
            @click="onExportFile"
          >
            <c-image
              :src="require('@/assets/icon-file-export.svg')"
              alt="icon"
              display="inline-block"
              marginRight="10px"
            />
            Export
          </c-button> -->
        </c-flex>
        <c-divider
          mx="23px"
          orientation="vertical"
        />
        <c-box
          flex-basis="248px"
          align-self="center"
        >
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              pos="absolute"
              top="10px"
              left="15px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              v-chakra="{
                '&::placeholder': {
                  color: 'gray.900',
                },
              }"
              type="text"
              w="100%"
              placeholder="Pencarian"
              background="superLightGray.900"
              border-radius="6px"
              padding-left="50px"
              pos="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box>
        <c-box>
          <c-grid
            w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
          >
            <c-box
              v-for="(item, index) in headings"
              :key="'heading-' + index"
              :w="item.width"
              py="11px"
              px="16px"
              color="primary.400"
              font-weight="bold"
              font-size="12px"
              line-height="18px"
              text-transform="uppercase"
              bg="rgba(0, 140, 129, 0.1)"
            >
              {{ item.label }}
            </c-box>
          </c-grid>

          <c-grid
            v-for="(item, index) in items"
            :key="item.id"
            w="100%"
            max-w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
            border-bottom="1px solid #C4C4C4"
          >
            <c-box
              :w="headings[0].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ numberingRow(index) }}
            </c-box>
            <c-box
              :w="headings[1].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.email }}
            </c-box>
            <c-box
              :w="headings[2].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.firstName }}
            </c-box>
            <c-box
              :w="headings[3].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.lastName }}
            </c-box>
            <c-box
              :w="headings[4].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.quota }}
            </c-box>
            <c-box
              :w="headings[5].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.remainingQuota }}
            </c-box>
            <c-box
              :w="headings[6].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.rating }}
            </c-box>
            <c-box
              :w="headings[7].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              <c-button
                h="30px"
                color="primary.400"
                border-radius="24px"
                border-color="primary.400"
                font-size="12px"
                line-height="18px"
                variant="outline"
                as="router-link"
                :to="{
                  name: 'admin.nutritionists.detail',
                  params: { id: item.id },
                }"
              >
                Lihat
              </c-button>
            </c-box>
          </c-grid>
          <c-flex
            v-if="!isEmpty"
            justify="space-between"
            py="28px"
            px="40px"
          >
            <c-flex align="center">
              <c-text
                color="gray.900"
                font-size="14px"
                line-height="21px"
              >
                Show:
              </c-text>
              <c-select
                v-model="perPage"
                min-w="120px"
                border-radius="6px"
                font-size="14px"
                ml="13px"
                size="md"
                @change="onSearch"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="15">
                  15 Rows
                </option>
                <option value="20">
                  20 Rows
                </option>
              </c-select>
            </c-flex>

            <c-flex
              justify-content="flex-end"
              w="100%"
            >
              <c-flex
                justify-content="center"
                align="center"
              >
                <c-button
                  p="0"
                  mr="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="currentPage === 1"
                  @click="prevPage"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'primary.400',
                    },
                  }"
                  p="0"
                  m="0"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  mx="4px"
                  border-radius="30px"
                  background-color="rgba(0, 140, 129, 0.3)"
                  color="primary.400"
                  :bg="
                    page_ === currentPage
                      ? ['rgba(0, 140, 129, 0.3)']
                      : ['transparent']
                  "
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  p="0"
                  ml="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="pages.length === page"
                  @click="nextPage"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-flex>
          <c-flex
            v-if="isEmpty"
            py="80px"
            px="20px"
            justify-content="center"
          >
            <c-box>
              <c-image
                :src="require('@/assets/empty-nutritionists.svg')"
                alt="empty"
              />
              <c-text
                color="gray.900"
                font-family="Roboto"
                font-size="24px"
                margin-top="20px"
              >
                Ahli Gizi tidak ditemukan
              </c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'ManagementNutritionistsPage',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      headings: [
        { label: 'no', width: '50px' },
        { label: 'email', width: '225px' },
        { label: 'nama depan', width: '190px' },
        { label: 'nama belakang', width: '170px' },
        { label: 'kuota', width: '70px' },
        { label: 'sisa kuota', width: '100px' },
        { label: 'rating', width: '70px' },
        { label: 'action', width: '90px' },
      ],
      page: 1,
      perPage: '10',
      search: this.$route.query.q ?? '',
      startDate: '',
      endDate: '',
      timer: null,
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      items: 'admNutritionists/items',
      meta: 'admNutritionists/meta',
    }),
    isEmpty() {
      return this.items.length === 0
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('q', this.search)
        params.set('startAt', this.startDate)
        params.set('endAt', this.endDate)
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        this.loadNutritionists(params)
      },
    },
  },
  methods: {
    ...mapActions({
      loadNutritionists: 'admNutritionists/listNutritionists',
      exportFileNutritionists: 'admNutritionists/exportFileNutritionists',
    }),
    onExportFile() {
      this.exportFileNutritionists()
        .then((res) => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob)
          downloadElement.href = href
          downloadElement.download = 'List Ahli Gizi.xlsx'
          document.body.appendChild(downloadElement)
          downloadElement.click()
          document.body.removeChild(downloadElement)
          window.URL.revokeObjectURL(href)
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onSearch() {
      this.page = 1
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            startAt: this.startDate,
            endAt: this.endDate,
            page: this.page,
            perpage: this.perPage,
          },
        })
      }, 800)
    },
    numberingRow(index) {
      return this.page * this.perPage - this.perPage + index + 1
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
  },
}
</script>